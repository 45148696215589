import Swal from 'sweetalert2';
import apiBase from '../apis/apiBase';

const qs = require('querystring');

export function LoginPost(username, password) {
    
                    
    apiBase.post('auth/login-fs', {email: username,password: password} )
    .then(user => {        
        console.log('user.data.ok', user.data.ok);
        console.log('user.data.token', user.data.token);
        console.log('user.data.roles', user.data.role);
        let rol = null;
        if(user.data.ok)
        {
            var vista ='';
            rol = user.data.role;
            localStorage.setItem('userToken', user.data.token);            
            localStorage.setItem('rol', user.data.role);
            localStorage.setItem('username', user.data.usuario.username);
            localStorage.setItem('useremail', user.data.usuario.email);            

            if(rol != null){
                if(user.data.role == 'rol-admin'){
                    vista = 'downloadfiles'
                }
                if(user.data.role == 'rol-gestion'){                     
                    vista = 'dashboard'
                }
                if(user.data.role == 'rol-gestion-admin'){ 
                    vista = 'downloadfiles'
                }
                if(user.data.role == 'rol-procesos'){ 
                    vista = 'downloadfiles'
                } 
            }
       
            ////////////////////////////////////////////////////////////////
            ////////////////////////////////////////////////////////////////
            console.log('vista: ', vista);
            localStorage.removeItem("NoAutorizado");
            window.location.href = '/' + vista;
        }else{
            Swal.fire('Oops...', 'Usuario o password invalido!', 'error');
        }

    })
    .catch(error => {
        console.log('Error', error);
        Swal.fire('Oops...', 'Usuario o password invalido!', 'error');
    })
    .finally(function () {
        // console.log('Respuesta Finally');
    })


  



    
}