import React, { Component, useReducer, useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import './interceptor';

// Vendor dependencies
import './Vendor';

// App Routes
import Routes from './Routes';
import './components/Ripple/Ripple.init.js';
require('./configuration');

const App = () => {
  // const basename = process.env.NODE_ENV === 'development' ? '/' : (PUBLIC_URL || '/');
  const basename = process.env.NODE_ENV === 'development' ? '/' : '/';    
            
    return (
        <BrowserRouter basename={basename}>
          <Routes />
        </BrowserRouter>
    );

}

export default App;


