import React from 'react';
import { completeSsoRedirect } from '../../configuration';
//import svgIcon from '../../assets/undraw_page_not_found_su7k.svg';
import './styless.css';

export const UnauthorizedScreen = () => {

  const onRedirect = () => {
    localStorage.clear();
    window.location.href = completeSsoRedirect;
  }

  return (
    <div className="unauthorized-content">
      {/*<img src={ svgIcon } alt="404" className="unauthorized-image" />*/}
      <h2>Contenido no disponible para usted.</h2>
      <button type="button" className="btn btn-link text-decoration-none" onClick={ onRedirect }>Ir al inicio de sesión</button>
    </div>
  )
}
