import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Link, withRouter } from "react-router-dom";
import pubsub from "pubsub-js";
import { withNamespaces } from "react-i18next";

import "./Sidebar.scss";

import SidebarRun from "./Sidebar.run";
import { SVGReplace } from "../Utils/Utils";

import Menu from "../../Menu.js";

const useremail = localStorage.getItem("useremail");
const SingleItemLabel = ({ label }) => (
    <span className="float-right nav-label">
        <span className={"badge " + label.className}></span>
    </span>
);

const SingleItemIcon = ({ src }) => (
    <span className="nav-icon">
        <SVGReplace src={src} className="invisible" />
    </span>
);

const SingleItem = ({ item }) => (
    <Link to={item.path} className="ripple">
        {item.label && <SingleItemLabel label={item.label} />}
        {item.icon && <SingleItemIcon src={item.icon} />}
        <span>{item.name}</span>
    </Link>
);

const SubMenuItem = ({ item, routeActive }) => [
    <a href={`#${item.name}`} className="ripple" key="0">
        <span className="float-right nav-caret">
            <em className="ion-ios-arrow-right" />
        </span>
        {item.label && <SingleItemLabel label={item.label} />}
        {item.icon && <SingleItemIcon src={item.icon} />}
        <span>{item.name}</span>
    </a>,
    <ul className="sidebar-subnav" key="1">
        {item.submenu.map((sitem, si) => (
            <li className={routeActive(sitem.path)} key={si}>
                <SingleItem item={sitem} />
            </li>
        ))}
    </ul>
];

const Sidebar = (props) => {
    const [sidebarModes, setSidebarModes] = useState({
        header: true,
        toolbar: true,
        offcanvas: false
    })

    const routeActive = paths => {
        paths = Array.isArray(paths) ? paths : [paths];
        return paths.some(p => props.location.pathname.indexOf(p) > -1)
            ? "active"
            : "";
    };

    const hasSubmenu = item => item.submenu && item.submenu.length;

    useEffect(() => {
        SidebarRun();
        pubsub.subscribe("sidebarmode", (msg, mode) => {
            setSidebarModes({
                ...sidebarModes,
                [mode]: !sidebarModes[mode]
            });
            if (mode === "offcanvas") {
                document.body.classList[
                    sidebarModes["offcanvas"] ? "add" : "remove"
                ]("sidebar-offcanvas");
            }
        });
        // Listen for routes changes in order to hide the sidebar on mobile
        props.history.listen(() => {
            document
                .querySelector(".layout-container")
                .classList.remove("sidebar-visible");
        });
        return () => {
            pubsub.unsubscribe(this.pubsub_token);
        }
    }, [])

    return (
        <aside className="sidebar-container">
            {sidebarModes.header && (
                <div className="sidebar-header">
                    <div id="hide-sidebar" className="sidebar-header-logo">
                        <a className="menu-link menu-link-slide back-btn">
                            <span><em></em></span>
                        </a>
                    </div>
                </div>
            )}
            <div className="sidebar-content">
                {sidebarModes.toolbar && (
                    <div className="sidebar-toolbar text-center">
                        <a href="#user">
                            <img
                                src="img/user/08.jpg"
                                alt="Profile"
                                className="rounded-circle thumb64"
                            />
                        </a>
                        <div className="mt">
                            <span className="span-user">{useremail}</span>
                        </div>
                    </div>
                )}                    
                <nav className="sidebar-nav mt">
                    <ul>
                        {/* Iterates over all sidebar menu items */}
                        {Menu.map((item, i) => {
                            const routes = hasSubmenu(item)
                                ? item.submenu.map(i => i.path)
                                : [item.path];
                            return (
                                <li className={routeActive(routes)} key={i}>
                                    {hasSubmenu(item) ? (
                                        <SubMenuItem item={item} routeActive={routeActive} />
                                    ) : (
                                        <SingleItem item={item} />
                                    )}
                                </li>
                            );
                        })}
                    </ul>
                </nav>
            </div>
        </aside>
    );
}

Sidebar.contextTypes = {
    router: PropTypes.object
};

Sidebar.propTypes = {
    location: PropTypes.object.isRequired
};

export default withNamespaces("translations")(withRouter(Sidebar));