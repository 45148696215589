// ==============================================================================
// Url base process.env.NODE_ENV = 'production' || 'development'
// ==============================================================================


export const SIZE_FILE  = 30000000;
const site = window.location.origin;
const productionMode = process.env.NODE_ENV === 'production';

export const webSSO = productionMode 
? 'https://sso.cadem.cl'
: 'http://localhost:4500';

export const API_SSO =  productionMode 
? 'https://backend-sso-xbstdiqb2a-ue.a.run.app'
: 'http://localhost:8081';

export const completeSsoRedirect = `${webSSO}/login?site=${utf8_to_b64(site)}`;
export const completeSsoLogout = `${webSSO}/logout?site=${utf8_to_b64(site)}`;

export const API_BASE_URL_NESTJS  = productionMode
? 'https://backend-web-cadem-online-ba7zkvym3a-ue.a.run.app'                    
: 'http://localhost:8080'; 

export const BASE_URL_USUARIOS = productionMode
? 'https://web-cademonline.cadem.cl/usuarios'
: 'http://localhost:3000';

function utf8_to_b64(str) {
    return window.btoa(unescape(encodeURIComponent( str )));
}