import React, { Suspense, lazy, useEffect } from 'react';
import { withRouter, Switch, Route, Redirect } from 'react-router-dom';
import { TransitionGroup, CSSTransition } from 'react-transition-group';



/* loader component for Suspense */
import PageLoader from './components/Common/PageLoader';

/* eslint-disable-next-line */
import Core from './components/Core/Core';
/* eslint-disable-next-line */
import Bootstrap from './components/Bootstrap/Bootstrap';
/* eslint-disable-next-line */
import Common from './components/Common/Common';
/* eslint-disable-next-line */
import Colors from './components/Colors/Colors';
/* eslint-disable-next-line */
import FloatButton from './components/FloatButton/FloatButton';
/* eslint-disable-next-line */
import Utils from './components/Utils/Utils';

import User from './views/User/User';
import apiBase from './apis/apiBase';
import { getCookieByName } from './helpers/getCookieByName';
import { checkLocalToken } from './services/UserSSOService';
import LoginRequireScreen from './views/NoLoginCP/LoginRequireScreen';
import { UnauthorizedScreen } from './views/NoLoginCP/UnauthorizedScreen';
import "react-block-ui/style.css";

/* Used to render a lazy component with react-router */
const waitFor = Tag => props => <Tag {...props}/>;


// const Cards = lazy(() => import('./views/Cards/Cards'));
// const Charts = lazy(() => import('./views/Charts/Charts'));
// const Forms = lazy(() => import('./views/Forms/Forms'));
// const Elements = lazy(() => import('./views/Elements/Elements'));
// const Tables = lazy(() => import('./views/Tables/Tables'));
// const Layouts = lazy(() => import('./views/Layouts/Layouts'));
// const Maps = lazy(() => import('./views/Maps/Maps'));
// const Pages = lazy(() => import('./views/Pages/Pages'));

const MantenedorUsuarios = lazy(() => import('./views/Archivos/MantenedorUsuarios'));


const Estudios = lazy(() => import('./views/Estudios/Estudios'));
const MarcoMuestral = lazy(() => import('./views/MarcoMuestral/MarcoMuestral'));
const MarcoMuestralList = lazy(() => import('./views/MarcoMuestral/MarcoMuestralList'));
const MuestrasView = lazy(() => import('./views/MarcoMuestral/MuestreoTab/index'));
const CrearMuestra = lazy(() => import('./views/MarcoMuestral/MuestreoTab/Muestreo'));
// const Metricas = lazy(() => import('./views/Metricas/Metricas'));
const DownloadFiles = lazy(() => import('./views/Download/DownloadFiles'));
const Dashboard = lazy(() => import('./views/Dashboard/Dashboard'));
const DashboardCalidad = lazy(() => import('./views/Dashboard/DashboardCalidad'));

const Panelistas = lazy(() => import('./views/Panelistas/Panelistas'));
const Participacion = lazy(() => import('./views/Panelistas/Participacion'));
const Unsubscribe = lazy(() => import('./views/Panelistas/Unsubscribe'));
const UsersMainScreen = lazy(() => import('./views/UserSSO/UsersMainScreen'));

const Campanas = lazy(() => import('./views/Campanas/index'));
const MantenedorInventario = lazy(() => import('./views/Premios/Mantenedor/MantenedorInventario'));
const Sortear = lazy(() => import('./views/Sorteos/index'));

//Dashboard tabs
const TotalPanel = lazy(() => import('./views/Dashboard/TotalPanel'));
const ActividadMensual = lazy(() => import('./views/Dashboard/ActividadMensual'));
const ParticipacionDashboard = lazy(() => import('./views/Dashboard/Participacion'));
const CampanasDashboard = lazy(() => import('./views/Dashboard/Campanas'));

const MantenedorSitioCademOnline = lazy(() => import('./views/SitioCademOnline/MantenedorSitioCademOnline'));

// const MantenedorTiposPremios = lazy(() => import('./views/Premios/TiposPremios/MantenedorTiposPremios'));

const PageNotFound = lazy(() => import('./views/Errors/PageNotFound'));


// List of routes that uses the page layout
// listed here to Switch between layouts
// depending on the current pathname
const listofPages = [
    '/login',
    '/recover',
    '/lock'
];

const PublicRoute = ({ isAuth, component: Component, ...rest }) => {
    return (
        <Route { ...rest } 
            component={(props) => ((!isAuth ) ? <Component { ...props } /> : ( <Redirect to="/downloadfiles" /> ))}
        />
    )
}

const Routes = ({ location }) => {
    const currentKey = location.pathname.split('/')[1] || '/';
    const timeout = { enter: 500, exit: 500 };

    const animationName = 'rag-fadeIn';

    const localToken = getCookieByName() || '';
    const logged = localStorage.getItem('log-ged') || '';
    const rolUser = localStorage.getItem('rol') || '';

    useEffect(() => {
        if(location.pathname !== "/unauthorized"){
            checkLocalToken()
        }
    }, [ location.pathname ])


    if(logged){
        return (
            <Switch>
                <Route exact path="/unauthorized" component={UnauthorizedScreen} />
                <Redirect to="/unauthorized" />
            </Switch>
        )
    }

    if(!localToken || !rolUser){
        return (
            <Suspense fallback={<PageLoader/>}>
                <Switch>
                    <Route exact path="/" component={LoginRequireScreen} />
                    <Route exact path="/unauthorized" component={UnauthorizedScreen} />
                    <Redirect to="/"/>
                </Switch>
            </Suspense>
        )
    }

    if(rolUser === 'rol-admin'){
        return (            
            <Core>
                <TransitionGroup>
                <CSSTransition key={currentKey} timeout={timeout} classNames={animationName} exit={false}>
                    <div>
                        <Suspense fallback={<PageLoader/>}>
                            <Switch location={location}>                                 
                                {/* descargas */}
                                <Route path="/downloadfiles" component={waitFor(DownloadFiles)}/>
                                {/* dashboard */}
                                <Route path="/dashboard" component={waitFor(Dashboard)}/>
                                <Route path="/total-panel" component={waitFor(TotalPanel)}/>
                                <Route path="/actividadmensual" component={waitFor(ActividadMensual)}/>
                                <Route path="/participacion-dashboard" component={waitFor(ParticipacionDashboard)}/>
                                <Route path="/campanas-dashboard" component={waitFor(CampanasDashboard)}/>
                                <Route path="/dashboardcalidad" component={waitFor(DashboardCalidad)}/>
                                {/* estudios */}
                                <Route path="/estudios" component={waitFor(Estudios)}/>
                                {/* marco muestral */}
                                <Route path="/lista-marcomuestral" component={waitFor(MarcoMuestralList)}/>                                    
                                <Route path="/marcomuestral" component={waitFor(MarcoMuestral)}/>
                                <Route path="/muestras" component={waitFor(MuestrasView)}/>
                                <Route path="/crear-muestra" component={waitFor(CrearMuestra)}/>
                                {/* panelistas */}
                                <Route path="/panelistas" component={waitFor(Panelistas)}/>
                                <Route path="/unsubscribe" component={waitFor(Unsubscribe)}/>
                                {/* sistama panel */}
                                <Route path="/campanas" component={waitFor(Campanas)}/>
                                <Route path="/inventario" component={waitFor(MantenedorInventario)}/>
                                <Route path="/sorteos" component={waitFor(Sortear)}/>
                                <Route path="/mantenedorsitiocademonline" component={waitFor(MantenedorSitioCademOnline)}/>
                                
                                {/* usuarios */}
                                {/*<Route path="/mantenedorusuarios" component={waitFor(MantenedorUsuarios)}/>*/}
                                <Route path="/usuarios" component={waitFor(UsersMainScreen)}/>
                                
                                {/* <Route path="/tipospremios" component={waitFor(MantenedorTiposPremios)}/> */}
                                {/* PageNotFound */}
                                <Route path="/PageNotFound" component={waitFor(PageNotFound)}/>
                                {/* <Redirect to="/PageNotFound"/> */}
                                <Redirect to="/dashboard"/>
                            </Switch>
                        </Suspense>
                    </div>
                </CSSTransition>
                </TransitionGroup>
            </Core>
        )
    }
    if(rolUser === 'rol-gestion'){
        return (            
            <Core>
                <TransitionGroup>
                <CSSTransition key={currentKey} timeout={timeout} classNames={animationName} exit={false}>
                    <div>
                        <Suspense fallback={<PageLoader/>}>
                            <Switch location={location}>
                                {/* dashboard */}
                                <Route path="/dashboard" component={waitFor(Dashboard)}/>
                                <Route path="/total-panel" component={waitFor(TotalPanel)}/>
                                <Route path="/actividadmensual" component={waitFor(ActividadMensual)}/>
                                <Route path="/participacion-dashboard" component={waitFor(ParticipacionDashboard)}/>
                                <Route path="/campanas-dashboard" component={waitFor(CampanasDashboard)}/>
                                <Route path="/dashboardcalidad" component={waitFor(DashboardCalidad)}/>
                                {/* PageNotFound */}
                                <Route path="/PageNotFound" component={waitFor(PageNotFound)}/>                                                                     
                                {/* <Redirect to="/PageNotFound"/> */}
                                <Redirect to="/dashboard"/>
                            </Switch>
                        </Suspense>
                    </div>
                </CSSTransition>
                </TransitionGroup>
            </Core>
        )
    }
    if(rolUser === 'rol-gestion-admin'){
        return (            
            <Core>
                <TransitionGroup>
                <CSSTransition key={currentKey} timeout={timeout} classNames={animationName} exit={false}>
                    <div>
                        <Suspense fallback={<PageLoader/>}>
                            <Switch location={location}>
                                {/* descargas */}
                                <Route path="/downloadfiles" component={waitFor(DownloadFiles)}/>
                                {/* dashboard */}
                                <Route path="/dashboard" component={waitFor(Dashboard)}/>
                                <Route path="/total-panel" component={waitFor(TotalPanel)}/>
                                <Route path="/actividadmensual" component={waitFor(ActividadMensual)}/>
                                <Route path="/participacion-dashboard" component={waitFor(ParticipacionDashboard)}/>
                                <Route path="/campanas-dashboard" component={waitFor(CampanasDashboard)}/>
                                <Route path="/dashboardcalidad" component={waitFor(DashboardCalidad)}/>
                                {/* estudios */}
                                <Route path="/estudios" component={waitFor(Estudios)}/>
                                {/* marco muestral */}
                                <Route path="/lista-marcomuestral" component={waitFor(MarcoMuestralList)}/>                                    
                                <Route path="/marcomuestral" component={waitFor(MarcoMuestral)}/>
                                <Route path="/muestras" component={waitFor(MuestrasView)}/>
                                <Route path="/crear-muestra" component={waitFor(CrearMuestra)}/>
                                {/* panelistas */}
                                <Route path="/panelistas" component={waitFor(Panelistas)}/>
                                <Route path="/unsubscribe" component={waitFor(Unsubscribe)}/>
                                {/* sistama panel */}
                                <Route path="/campanas" component={waitFor(Campanas)}/>
                                <Route path="/inventario" component={waitFor(MantenedorInventario)}/>
                                <Route path="/sorteos" component={waitFor(Sortear)}/>
                                {/* PageNotFound */}
                                <Route path="/PageNotFound" component={waitFor(PageNotFound)}/>                                                                     
                                {/* <Redirect to="/PageNotFound"/> */}
                                <Redirect to="/downloadfiles"/>
                            </Switch>
                        </Suspense>
                    </div>
                </CSSTransition>
                </TransitionGroup>
            </Core>
        )
    }        
    if(rolUser === 'rol-procesos'){
        return (            
            <Core>
                <TransitionGroup>
                <CSSTransition key={currentKey} timeout={timeout} classNames={animationName} exit={false}>
                    <div>
                        <Suspense fallback={<PageLoader/>}>
                            <Switch location={location}>
                                {/* descargas */}
                                <Route path="/downloadfiles" component={waitFor(DownloadFiles)}/>
                                {/* dashboard */}
                                <Route path="/dashboard" component={waitFor(Dashboard)}/>
                                <Route path="/total-panel" component={waitFor(TotalPanel)}/>
                                <Route path="/actividadmensual" component={waitFor(ActividadMensual)}/>
                                <Route path="/participacion-dashboard" component={waitFor(ParticipacionDashboard)}/>
                                <Route path="/campanas-dashboard" component={waitFor(CampanasDashboard)}/>
                                <Route path="/dashboardcalidad" component={waitFor(DashboardCalidad)}/>
                                {/* marco muestral */}
                                <Route path="/lista-marcomuestral" component={waitFor(MarcoMuestralList)}/>                                    
                                <Route path="/marcomuestral" component={waitFor(MarcoMuestral)}/>
                                <Route path="/muestras" component={waitFor(MuestrasView)}/>
                                <Route path="/crear-muestra" component={waitFor(CrearMuestra)}/>
                                {/* PageNotFound */}                                 
                                <Route path="/PageNotFound" component={waitFor(PageNotFound)}/>                                                                     
                                {/* <Redirect to="/PageNotFound"/> */}
                                <Redirect to="/downloadfiles"/>
                            </Switch>
                        </Suspense>
                    </div>
                </CSSTransition>
                </TransitionGroup>
            </Core>
        )
    }
}

export default withRouter(Routes);
