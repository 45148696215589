import React, { useEffect, useState } from "react";
import { Redirect } from 'react-router-dom';
import pubsub from 'pubsub-js';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import './Header.scss';
import './HeaderMenuLinks.scss';
import { completeSsoLogout } from "../../configuration";

const Header = () =>{
    const [state, setState] = useState({
        pageTitle: '',
        navIcon: 'menu-link-slide',
        dropdownOpen: false
    })
    
    const { pageTitle, navIcon, dropdownOpen } = state;
    const toggle = () => {
        setState(prevState => ({
            ...prevState,
            dropdownOpen: !prevState.dropdownOpen
        }));
    }

    const logout = () => {
        localStorage.clear();
        window.location.href = completeSsoLogout;
    }

    useEffect(() => {
        pubsub.subscribe('setPageTitle', (ev, title) => setState(prevState => ({ ...prevState, pageTitle: title})));
        pubsub.subscribe('setNavIcon', (ev, icon) => setState(prevState => ({ ...prevState, navIcon: icon})));
        return () => {
            pubsub.unsubscribe(this.pubsub_token);
            pubsub.unsubscribe(this.pubsub_token_icon);
        }
    }, [])

    return (
        <header className="header-container">
            <nav>
                <ul className="d-lg-block">
                    <li>
                        <a id="show-sidebar" className={"menu-link "+navIcon}>
                            <span><em></em></span>
                        </a>
                    </li>
                </ul>
                <h2 className="header-title">{pageTitle}</h2>
                <ul className="float-right">
                    <Dropdown id="basic-nav-dropdown" tag="li" isOpen={dropdownOpen} toggle={toggle}>
                        <DropdownToggle nav className="has-badge ripple">
                            <em className="ion-gear-b"></em>
                        </DropdownToggle>
                        <DropdownMenu right className="md-dropdown-menu" >
                                <a 
                                    type="button"
                                    className="btn btn-link text-decoration-none w-100"
                                    onClick={logout}
                                >
                                    <DropdownItem>
                                        <em className="ion-log-out icon-fw"></em>
                                        Salir
                                    </DropdownItem >
                                </a>
                            </DropdownMenu>
                    </Dropdown>
                </ul>
            </nav>
        </header>
    );
}

export default Header;
