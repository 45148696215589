import { completeSsoRedirect } from "./configuration";
import { deleteAllCookies } from "./helpers/deleteAllCookies";
import { getCookieByName } from "./helpers/getCookieByName";
import apiBase from './apis/apiBase';

apiBase.interceptors.request.use(
  function (config) {
    const token = getCookieByName();
    config.headers = {
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept",
      Authorization: "Bearer " + token,
    };

    return config;
  },

  function (error) {
    return Promise.reject(error);
  }
);

// Add a response interceptor
apiBase.interceptors.response.use(
  function (response) {
    if(response.status === 202 && response.config.url.endsWith('check-credentials')) {
      const currentSite = window.location.origin;
      const data = response.data;
      const siteRol = data.user.user.roles.find(item => item.USER_SITE_URL === currentSite);

      if(siteRol){
        localStorage.setItem('rol', siteRol.USER_SITE_ROL || '');
      }

      if(!siteRol || !siteRol.USER_SITE_ROL){
        localStorage.setItem('log-ged', 'not-logged');
        localStorage.setItem('rol', '');
        window.location.href = "/unauthorized";
      }
    }

    return response;
  },

  function (error) {
    if (error.response.data.statusCode === 401) {
      if (error.response.data.message === "Unauthorized") {
        if('caches' in window){
          caches.keys().then((names) => {
            // Delete all the cache files
            names.forEach(name => {
              console.log('Cache -> ' + name)
              caches.delete(name);
            });
          });
        };
        deleteAllCookies();
        window.location.href = completeSsoRedirect;
      }
    }
    return Promise.reject(error);
  }
);