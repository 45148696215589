import apiBase from "../apis/apiBase";
import {  API_BASE_URL_NESTJS, API_SSO } from "../configuration";

export const getAllUsers = async () => {
  try {
    const origin = window.location.origin;
    const url = `${API_SSO}/user/get-users?origin=${origin}`;
    const response = await apiBase.get(url);
    return response.data;
  } catch (error) {
    return { ok: false, error }
  }
}

export const addNewUser = async (user) => {
  try {
    const response = await apiBase({
      url: `${API_SSO}/user/create-user?origin=${origin}`,
      method: 'POST',
      data: user
    });

    return response.data;
  } catch (error) {
    return { ok: false, error }
  }
}

export const editCurrentUser = async (user, userId) => {
  try {
    const response = await apiBase({
      url: `${API_SSO}/user/edit-user/${userId}?origin=${origin}`,
      method: 'PUT',
      data: user
    });

    return response.data;
  } catch (error) {
    return { ok: false, error }
  }
}

export const updatePassword = async (userId, password) => {
  try {
    const response = await apiBase({
      url: `${API_SSO}/user/update-password/${userId}?origin=${origin}`,
      method: 'PUT',
      data: { password }
    });

    return response.data;
  } catch (error) {
    return { ok: false, error }
  }
}

export const resetPassword = async (email) => {
  try {
    const response = await apiBase({
      url: `${URL}/user/reset-password/`,
      method: 'POST',
      data: { email }
    });

    return response.data;
  } catch (error) {
    return { ok: false, error }
  }
}

export const createRoleUser = async (userEmail, userRole) => {
  try {
    const origin = window.location.origin;
    const response = await apiBase({
      url: `${API_SSO}/user/create-rol?origin=${origin}`,
      method: 'POST',
      data: { userEmail, userRole }
    });

    return response.data;
  } catch (error) {
    return { ok: false, error }
  }
}

export const updateRoleUser = async (userId, newRole) => {
  try {
    const origin = window.location.origin;
    const response = await apiBase({
      url: `${API_SSO}/user/update-role/${userId}?origin=${origin}`,
      method: 'PUT',
      data: { newRole }
    });

    return response.data;
  } catch (error) {
    return { ok: false, error }
  }
}

export const removeRolUserById = async (userId) => {
  try {
    const origin = window.location.origin;
    const response = await apiBase({
      url: `${API_SSO}/user/remove-role/${userId}?origin=${origin}`,
      method: 'PUT',
    });

    return response.data;
  } catch (error) {
    return { ok: false, error }
  }
}

export const checkLocalToken = async () => {
  try {
    const url = API_BASE_URL_NESTJS+'/auth/check-credentials';
    const response = await apiBase.get(url);
    return response.data;
  } catch (error) {
    return { ok: false, error }
  }
}